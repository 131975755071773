<template>
  <div class="vps-detail-view">
    <transition name="bubble">
      <!--      <div v-if="isLoading">-->
      <!--        <base-loader class="stack-detail-view__loader-item" />-->
      <!--      </div>-->
      <page-block class="vps-detail-view__block">
        <main-card>
          <div class="addons">
            <div class="addons__item">
              <div class="addons__label medium-title">{{ $t('name') }}</div>
              <div class="addons__value medium-text">{{ tariff.name }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label medium-title">{{ $t('id') }}</div>
              <div class="addons__value medium-text">{{ tariff.id }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label medium-title">{{ $t('projectid') }}</div>
              <div class="addons__value medium-text">{{ tariff.project_id }}</div>
            </div>
            <!--            <div class="addons__item">-->
            <!--              <div class="addons__label medium-title">{{ $t('network') }}</div>-->
            <!--              <div class="addons__value medium-text">{{ networkName }}</div>-->
            <!--            </div>-->
            <div class="addons__item">
              <div class="addons__label medium-title">{{ $t('networkid') }}</div>
              <div class="addons__value medium-text">{{ tariff.network_id }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label medium-title">{{ $t('pool') }}</div>
              <div class="addons__value medium-text">
                {{ !!tariff.subnetpool_id ? tariff.subnetpool_id : 'Нет' }}
              </div>
            </div>
            <div class="addons__item">
              <div class="addons__label medium-title">{{ $t('ip') }}</div>
              <div class="addons__value medium-text">{{ 'IPv' + tariff.ip_version }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label medium-title">{{ $t('cidr') }}</div>
              <div class="addons__value medium-text">{{ tariff.cidr }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label medium-title">{{ $t('poolip') }}</div>
              <div class="addons__value medium-text">
                {{
                  'Начало ' +
                  tariff.allocation_pools[0].start +
                  ' - ' +
                  'Конец ' +
                  tariff.allocation_pools[0].end
                }}
              </div>
            </div>
            <div class="addons__item">
              <div class="addons__label medium-title">{{ $t('gateway') }}</div>
              <div class="addons__value medium-text">{{ tariff.gateway_ip }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label medium-title">{{ $t('dhcp') }}</div>
              <div class="addons__value medium-text">{{ tariff.enable_dhcp ? 'Да' : 'Нет' }}</div>
            </div>
            <!--            <div class="addons__item">-->
            <!--              <div class="addons__label medium-title">{{ $t('additional') }}</div>-->
            <!--              <div class="addons__value medium-text">{{ 'Нет' }}</div>-->
            <!--            </div>-->
            <div class="addons__item">
              <div class="addons__label medium-title">{{ $t('dns') }}</div>
              <div class="addons__value medium-text">
                <div class="addons__dns">
                  <div class="addons">{{ tariff.dns_nameservers[0] }}</div>
                  <div class="addons">{{ tariff.dns_nameservers[1] }}</div>
                </div>
              </div>
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
// import TariffsTablePublic from '@/layouts/Stack/pages/Main/components/TariffsTablePublic';
export default {
  name: 'SubnetPublicView',
  components: {
    // TariffsTablePublic,
  },
  mixins: [providerChange],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
  },
  data() {
    return {
      // tableHead: [
      //   {
      //     key: 'address',
      //     label: this.$t('tableHead.address'),
      //     style: {
      //       width: '400px',
      //       maxWidth: '460px',
      //     },
      //   },
      // {
      //   key: 'ip',
      //   label: this.$t('tableHead.ip'),
      //   style: {
      //     width: '44px',
      //   },
      // },
      // {
      //   key: 'dhcp',
      //   label: this.$t('tableHead.dhcp'),
      //   style: {
      //     width: '44px',
      //   },
      // },
      // {
      //   key: 'gateway',
      //   label: this.$t('tableHead.gateway'),
      //   style: {
      //     width: '424px',
      //     maxWidth: '500px',
      //   },
      // },
      // {
      //   key: 'status',
      //   label: this.$t('tableHead.status'),
      //   style: {
      //     width: '134px',
      //   },
      // },
      // {
      //   label: this.$t('tableHead.menu'),
      //   key: 'more',
      //   style: {
      //     width: '44px',
      //   },
      // },
      // ],
      // isLoading: true,
      // networkName: 'Загрузка...',
    };
  },
  computed: {
    instance() {
      return this.$store.state.moduleStack.servers.find(x => x.id === this.serverId) || '';
    },
    subnetsId() {
      return this.tariff.subnets;
    },
    subnets() {
      return this.$store.state.moduleStack.subnets.filter(x => x.network_id === this.tariff.id);
    },
    diskId() {
      return this.tariff.id;
    },
  },
  // mounted() {
  // setTimeout(() => this.isNetworkName(), 300);
  // },
  methods: {
    // isNetworkName() {
    //   if (this.$store.state.moduleStack.networks && this.tariff.network_id) {
    //     return (this.networkName = this.$store.state.moduleStack.networks.find(
    //       x => x.id === this.tariff.network_id
    //     ).name);
    //   }
    // },
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
        "name": "Имя",
        "id": "ID",
        "projectid": "ID проекта",
      "network": "Имя сети",
      "networkid": "ID сети",
      "pool": "Пул подсети",
      "ip": "Версия IP",
      "cidr": "CIDR",
      "poolip": "Пулы назначения IP",
      "gateway": "IP шлюза",
      "dhcp": "Разрешен DHCP",
      "additional": "Дополнительные маршруты",
      "dns": "Сервера DNS"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 160px;
      margin: 0.5em 0.75rem;
    }
  }
}

.addons {
  &__dns{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
<script setup></script>
